import React, { useContext, useState } from "react"
import Layout from "../../../components/layout"
import ThemeContext from "../../../context/ThemeContext"
import _ from '../../../services/NullIf'
import t from "../../../services/GetTranslationValue"
import { graphql } from "gatsby"
import SEO from "../../../components/seo"
import ContentWrapper from '../../../components/text-helpers/content-wrapper'
import styles from "./index.module.scss"
import stylesTabs from "../../special-landing/styles.module.scss"
import GameProvidersCards from "../../../components/game-providers-cards/game-providers-cards"
import { Tab, Tabs, Typography } from "@material-ui/core"
import localize from "../../../components/localize"

const Content = ({ paymentMethods, translations, locale }) => {

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`horizontal-tabpanel-${index}`}
        aria-labelledby={`horizontal-tab-${index}`}
        {...other}
      >
        {children}
      </Typography>
    )
  }

  function TabsIndex(index) {
    return {
      id: `horizontal-tab-${index}`,
      "aria-controls": `horizontal-tabpanel-${index}`,
    }
  }

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }


  return (
    <div className={`${stylesTabs.bonusPageTabsContainer} ${styles.tabParent}`}>
      <Tabs
        orientation="horizontal"
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        className={`gamble-tab ${styles.tabs}`}
      >
        <Tab
          label={t.get(translations, 'deposit-methods')}
          {...TabsIndex(0)}
          className={`${styles.tab}`}
        />
        <Tab
          label={t.get(translations, 'withdrawal-methods')}
          {...TabsIndex(1)}
          className={`${styles.tab}`}
        />
      </Tabs>

      <TabPanel
        value={value}
        index={0}
        className={`${stylesTabs.tabContent}`}
      >
        <div className={`${stylesTabs.tabBody}`}>
          <GameProvidersCards iterable={paymentMethods} iterableName={"depositMethods"}
                  translationName={"depositMethods"} translations={translations} locale={locale} />
        </div>
      </TabPanel>

      <TabPanel
        value={value}
        index={1}
        className={`${stylesTabs.tabContent}`}
      >
        <div className={`${stylesTabs.tabBody}`}>
          <GameProvidersCards iterable={paymentMethods.filter(x => _.isNotNullOrEmpty(x.maximumWithdrawalTime))} iterableName={"withdrawalMethods"}
            translationName={"withdrawalMethods"} translations={translations} locale={locale} />
        </div>
      </TabPanel>

    </div>

  )
}

const BankingMethods = ({ data, pageContext, location }) => {
  const { locale, alternateLocaleSlugs } = pageContext
  let { edges } = data.allSanityPaymentMethod
  let nodes = edges.map(x => x.node).filter(x => !x.disabledLocales || !x.disabledLocales.some(y => y === locale))

  let { translations } = useContext(ThemeContext)

  const pageData = data.sanityGenericLandingPage;
  nodes.forEach(node => {
    node.name = node.displayName || node.name;
  })

  return (
    <Layout  silo={data.sanitySilo} breadcrumbNameOverride={pageData.breadcrumbName} pageContext={pageContext} location={location}>
      <SEO location={location} alternateSlug={alternateLocaleSlugs}
           title={pageData && pageData.seoTitle}
           description={pageData && pageData.seoMeta}
      />
      <ContentWrapper banner={pageData.heroImage} title={pageData.h1Tag} header={pageData._rawHeaderText}
                      footer={pageData._rawFooterText}
                      headerClassName={`gambleGeneralWrapper`} bodyClassName={`gambleGeneralWrapper`}>
        <Content
          translations={translations}
          locale={locale}
          paymentMethods={nodes}
        />
      </ContentWrapper>
    </Layout>
  )
}

export default localize(BankingMethods)
export const query = graphql`
  query BankingMethodsQuery {
    sanitySilo(_id: {eq: "76ec0ccd-5a65-4f6b-9e82-857614680f23"}){
      _id
      breadcrumbTrail { 
          ...LocaleSiloTrails
      }
    }
    sanityGenericLandingPage(
      _id: { eq: "2H8sE9k2EsDwgcwOJ3GdR6" }) {
      ...GenericLanding
    }
    allSanityPaymentMethod {
      edges {
        node {
          name
          _updatedAt
          disabledLocales
          displayName{
              ...LocaleString
          }
          minimumWithdrawalTime
          maximumWithdrawalTime 
          largeIcon {
            asset {
                fluid {
                    ...GatsbySanityImageFluid
                }
            }
          }
        }
      }
    }
  }
`
